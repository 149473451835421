<template>
  <div class="applicants">
    <div style="display: flex; flex-direction: row">
      <h2 class="applicants__header" style="margin-right: 19px">Претенденты</h2>
      <span class="applicants__sum" v-html="orgArray.length"></span>
      <button
        class="usual-btn usual-btn_bold"
        style="margin: 0 20px 0 0"
        @click="printingOrders = true">
        Печать приказов
      </button>
      <router-link :to="{ name: 'JournalsEditing2024' }">
        <button class="usual-btn usual-btn_bold" style="margin: 0 20px 0 auto">
          Редактирование журналов
        </button>
      </router-link>
      <button
        class="usual-btn usual-btn_bold"
        style="margin: 0 20px 0 0"
        @click="downloadDialog = true">
        Выгрузка
      </button>
      <router-link :to="{ name: 'ApplicantsSelectionMns2024' }">
        <button class="usual-btn usual-btn_bold" style="margin: 0 20px 0 auto">
          Отбор претендентов
        </button>
      </router-link>
      <router-link :to="{ name: 'KonvertRegistrationMns2024' }">
        <button class="usual-btn usual-btn_bold" style="margin: 0 0 0 auto">
          Регистрация конвертов
        </button>
      </router-link>
    </div>
    <div style="display: flex; flex-direction: row; margin-top: 17px; margin-bottom: 22px">
      <v-text-field
        v-model="search"
        label="Начните набирать название организации..."
        style="max-width: 400px"/>
      <v-text-field
        v-model="searchFio"
        label="Начните набирать фамилию..."
        style="max-width: 400px; margin-left: 40px"/>
    </div>
    <v-dialog v-model="printingOrders" width="740px">
      <v-card class="applicantsOrgDocs" style="min-height: 590px; padding-bottom: 30px">
        <h2 class="applicantsOrgDocs__header">Печать приказов</h2>
        <img
          @click="printingOrders = false"
          class="dialog__exit"
          src="@/assets/icons/dialog-exit.png"
          alt="exit"/>
        <div class="applicantsOrgDocs-div" v-for="order in orders" :key="order.path">
          <div style="display: flex; flex-direction: row; width: 700px">
            <p class="document-name">{{ order.text }}</p>
            <button
              v-if="!order.loading"
              class="usual-btn usual-btn_bold"
              @click="getSelectedOrder(order.path)"
              style="margin: 0 20px 0 auto">
              Скачать
            </button>
            <div
              v-else
              style="margin: 0 20px 0 auto; min-height: 32px; width: 110px"
              class="d-flex flex-row align-center">
              <v-progress-linear indeterminate></v-progress-linear>
            </div>
          </div>
        </div>
        <button class="usual-btn" style="margin: 23px auto 0 25px" @click="printingOrders = false">
          Закрыть
        </button>
      </v-card>
    </v-dialog>
    <!--                    applicantDocsDialog                     -->
    <v-dialog v-model="applicantDocsDialog" width="740px">
      <v-card
        class="applicantsOrgDocs"
        :style="docs.oldFioRow ? 'min-height: 910px' : 'min-height: 840px'">
        <h2 class="applicantsOrgDocs__header">Заявка претендента</h2>
        <img
          @click="applicantDocsDialog = false"
          class="dialog__exit"
          src="@/assets/icons/dialog-exit.png"
          alt="exit"/>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column">
            <p class="document-name" style="font-weight: 500">{{ docs.fio }}</p>
            <p
              class="document-file document-file_empty"
              style="color: #89a0b0; border-bottom: none">
              {{ docs.info }}
            </p>
          </div>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Опись</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            @click="getInventory(docs.rowID)"
            alt="print"
            class="document-print"/>
          <!--    визуальная заглушка     -->
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            accept="application/pdf"
            placeholder="Выберите файл"
            style="visibility: hidden"></v-file-input>
          <!--    визуальная заглушка     -->
          <img
            style="visibility: hidden"
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"/>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Лист проверки</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            @click="getCheckList(docs.rowID)"
            class="document-print"/>
          <!--    визуальная заглушка     -->
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            accept="application/pdf"
            placeholder="Выберите файл"
            style="visibility: hidden"></v-file-input>
          <!--    визуальная заглушка     -->
          <img
            style="visibility: hidden"
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"/>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Характеристика-рекомендация претендента (Приложение 1)</p>
            <p
              class="document-file"
              v-html="`${docs.character1.fileNameOrig}.${docs.character1.fileExt}`"
              v-if="character1Computed !== 'Файл не загружен'"
              @click="
                getFile(docs.character1.uuid, docs.character1.fileNameOrig, docs.character1.fileExt)
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            @click="getAppendix1(docs.rowID)"
            class="document-print"/>
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="character1File"
            :style="character1FileStyleDependsOnKonvert ? 'visibility: hidden' : ''"
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_CHARACTER_1', character1File)"></v-file-input>
          <img
            :style="character1Computed !== 'Файл загружен' ? 'visibility: hidden' : ''"
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteFile(docs.character1.uuid, 'FILE_CHARACTER_1')"/>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Приложение к характеристике-рекомендации (Приложение 2)</p>
            <p
              class="document-file"
              v-html="`${docs.character2.fileNameOrig}.${docs.character2.fileExt}`"
              v-if="character2Computed !== 'Файл не загружен'"
              @click="
                getFile(docs.character2.uuid, docs.character2.fileNameOrig, docs.character2.fileExt)
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            @click="getAppendix2(docs.rowID)"
            class="document-print"/>
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="character2File"
            :style="character2FileStyleDependsOnKonvert ? 'visibility: hidden' : ''"
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_CHARACTER_2', character2File)"></v-file-input>
          <img
            :style="character2Computed !== 'Файл загружен' ? 'visibility: hidden' : ''"
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteFile(docs.character2.uuid, 'FILE_CHARACTER_2')"/>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Сопроводительное письмо</p>
            <p
              v-html="`${docs.coverLetter.fileNameOrig}.${docs.coverLetter.fileExt}`"
              v-if="coverLetterComputed !== 'Файл не загружен'"
              class="document-file"
              @click="
                getFile(
                  docs.coverLetter.uuid,
                  docs.coverLetter.fileNameOrig,
                  docs.coverLetter.fileExt,
                )
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            style="visibility: hidden"
            class="document-print"/>
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="coverLetterFile"
            :style="coverLetterFileStyleDependsOnKonvert ? 'visibility: hidden' : ''"
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_COVER_LETTER', coverLetterFile)"></v-file-input>
          <img
            :style="coverLetterComputed !== 'Файл загружен' ? 'visibility: hidden' : ''"
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteFile(docs.coverLetter.uuid, 'FILE_COVER_LETTER')"/>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Согласие кандидата на обработку, хранение и передачу ПД</p>
            <p
              class="document-file"
              v-html="`${docs.approval.fileNameOrig}.${docs.approval.fileExt}`"
              v-if="approvalComputed !== 'Файл не загружен'"
              @click="
                getFile(docs.approval.uuid, docs.approval.fileNameOrig, docs.approval.fileExt)
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            style="visibility: hidden"
            class="document-print"/>
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="approvalFile"
            :style="approvalFileStyleDependsOnKonvert ? 'visibility: hidden' : ''"
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_SOGL', approvalFile)"></v-file-input>
          <img
            :style="approvalComputed !== 'Файл загружен' ? 'visibility: hidden' : ''"
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteFile(docs.approval.uuid, 'FILE_SOGL')"/>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">
              Документ, подтверждающий принадлежность к коренным малочисленным народам Севера,
              Сибири и Дальнего Востока
            </p>
            <p
              v-html="`${docs.affiliationMns.fileNameOrig}.${docs.affiliationMns.fileExt}`"
              v-if="affiliationMnsComputed !== 'Файл не загружен'"
              class="document-file"
              @click="
                getFile(
                  docs.affiliationMns.uuid,
                  docs.affiliationMns.fileNameOrig,
                  docs.affiliationMns.fileExt,
                )
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            style="visibility: hidden"
            class="document-print"/>
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="affiliationMnsFile"
            :style="affiliationMnsFileStyleDependsOnKonvert ? 'visibility: hidden' : ''"
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_AFFILATIONS', affiliationMnsFile)"></v-file-input>
          <img
            :style="affiliationMnsComputed !== 'Файл загружен' ? 'visibility: hidden' : ''"
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteFile(docs.affiliationMns.uuid, 'FILE_AFFILATIONS')"/>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Выписка из протокола заседания ученого совета</p>
            <p
              class="document-file"
              v-html="`${docs.councilDecision.fileNameOrig}.${docs.councilDecision.fileExt}`"
              v-if="councilDecisionComputed !== 'Файл не загружен'"
              @click="
                getFile(
                  docs.councilDecision.uuid,
                  docs.councilDecision.fileNameOrig,
                  docs.councilDecision.fileExt,
                )
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            style="visibility: hidden"
            class="document-print"/>
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="councilDecisionFile"
            :style="councilDecisionFileStyleDependsOnKonvert ? 'visibility: hidden' : ''"
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_SOVET', councilDecisionFile)"></v-file-input>
          <img
            :style="councilDecisionComputed !== 'Файл загружен' ? 'visibility: hidden' : ''"
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteFile(docs.councilDecision.uuid, 'FILE_SOVET')"/>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Сведения об успеваемости</p>
            <p
              v-html="
                `${docs.academicPerformance.fileNameOrig}.${docs.academicPerformance.fileExt}`
              "
              v-if="academicPerformanceComputed !== 'Файл не загружен'"
              class="document-file"
              @click="
                getFile(
                  docs.academicPerformance.uuid,
                  docs.academicPerformance.fileNameOrig,
                  docs.academicPerformance.fileExt,
                )
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            style="visibility: hidden"
            class="document-print"/>
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="academicPerformanceFile"
            :style="academicPerformanceFileStyleDependsOnKonvert ? 'visibility: hidden' : ''"
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_USPEH', academicPerformanceFile)"></v-file-input>
          <img
            :style="academicPerformanceComputed !== 'Файл загружен' ? 'visibility: hidden' : ''"
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteFile(docs.academicPerformance.uuid, 'FILE_USPEH')"/>
        </div>
        <div class="applicantsOrgDocs-div" v-if="docs.oldFioRow">
          <div style="display: flex; flex-direction: column; width: 700px">
            <p class="document-name">Документ, подтверждающий смену ФИО</p>
            <p
              v-html="`${docs.oldFioObject.fileNameOrig}.${docs.oldFioObject.fileExt}`"
              v-if="oldFioComputed !== 'Файл не загружен'"
              class="document-file"
              @click="
                getFile(
                  docs.oldFioObject.uuid,
                  docs.oldFioObject.fileNameOrig,
                  docs.oldFioObject.fileExt,
                )
              "></p>
            <p class="document-file document-file_empty" v-else>Не загружено</p>
          </div>
          <!--заглушка для стилей-->
          <img
            src="@/assets/icons/print.svg"
            alt="print"
            style="visibility: hidden"
            class="document-print"/>
          <v-file-input
            prepend-icon="mdi-folder-open-outline"
            v-model="oldFioFile"
            :style="oldFioObjectFileStyleDependsOnKonvert ? 'visibility: hidden' : ''"
            accept="application/pdf"
            placeholder="Выберите файл"
            @change="filePost('FILE_OLD_FIO', oldFioFile)"></v-file-input>
          <img
            :style="oldFioComputed !== 'Файл загружен' ? 'visibility: hidden' : ''"
            src="@/assets/icons/delete-round.svg"
            alt="delete"
            class="document-delete"
            @click="deleteFile(docs.oldFioObject.uuid, 'FILE_OLD_FIO')"/>
        </div>
        <button
          class="usual-btn"
          style="margin: 23px auto 26px 25px"
          @click="applicantDocsDialog = false">
          Закрыть
        </button>
      </v-card>
    </v-dialog>
    <v-dialog v-model="downloadDialog" width="740px">
      <v-card class="applicantsOrgDocs" style="min-height: 460px">
        <h2 class="applicantsOrgDocs__header">Выгрузка</h2>
        <img
          @click="downloadDialog = false"
          class="dialog__exit"
          src="@/assets/icons/dialog-exit.png"
          alt="exit"/>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: row; width: 700px">
            <p class="document-name">Данные претендентов</p>
            <button
              v-if="!loader.button1"
              class="usual-btn usual-btn_bold"
              @click="getApplicantDataExcel"
              style="margin: 0 20px 0 auto">
              Скачать
            </button>
            <div
              v-else
              style="margin: 0 20px 0 auto; min-height: 32px; width: 110px"
              class="d-flex flex-row align-center">
              <v-progress-linear indeterminate></v-progress-linear>
            </div>
          </div>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: row; width: 700px">
            <p class="document-name">Отбор претендентов</p>
            <button
              v-if="!loader.button2"
              class="usual-btn usual-btn_bold"
              @click="getApplicantSelectionExcel"
              style="margin: 0 20px 0 auto">
              Скачать
            </button>
            <div
              v-else
              style="margin: 0 20px 0 auto; min-height: 32px; width: 110px"
              class="d-flex flex-row align-center">
              <v-progress-linear indeterminate></v-progress-linear>
            </div>
          </div>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: row; width: 700px">
            <p class="document-name">Количество поданных заявок</p>
            <button
              v-if="!loader.button3"
              class="usual-btn usual-btn_bold"
              @click="getSelectedApplicationsSumExcel"
              style="margin: 0 20px 0 auto">
              Скачать
            </button>
            <div
              v-else
              style="margin: 0 20px 0 auto; min-height: 32px; width: 110px"
              class="d-flex flex-row align-center">
              <v-progress-linear indeterminate></v-progress-linear>
            </div>
          </div>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: row; width: 700px">
            <p class="document-name">Количество отобранных заявок</p>
            <button
              v-if="!loader.button4"
              class="usual-btn usual-btn_bold"
              @click="getSubmittedApplicationsSumExcel"
              style="margin: 0 20px 0 auto">
              Скачать
            </button>
            <div
              v-else
              style="margin: 0 20px 0 auto; min-height: 32px; width: 110px"
              class="d-flex flex-row align-center">
              <v-progress-linear indeterminate></v-progress-linear>
            </div>
          </div>
        </div>
        <div class="applicantsOrgDocs-div">
          <div style="display: flex; flex-direction: row; width: 700px">
            <p class="document-name">Уставы, ЕГРЮЛ</p>
            <button
              v-if="!loader.button5"
              class="usual-btn usual-btn_bold"
              @click="getOrgDocsExcel"
              style="margin: 0 20px 0 auto">
              Скачать
            </button>
            <div
              v-else
              style="margin: 0 20px 0 auto; min-height: 32px; width: 110px"
              class="d-flex flex-row align-center">
              <v-progress-linear indeterminate></v-progress-linear>
            </div>
          </div>
        </div>
        <button
          class="usual-btn"
          style="margin: 23px auto 26px 25px"
          @click="applicantDocsDialog = false">
          Закрыть
        </button>
      </v-card>
    </v-dialog>
    <!--                    applicantDocsDialog                     -->
    <GridTable
      :data-rows="showingServerData"
      :headers="tableHeaders.orgMassive"
      :headers-for-rows="tableHeaders.orgMassive"
      :num-cols="tableHeaders.countCells"
      :loading-data="loading"
      :borderTop="'none'"
      style="background: #ffffff; box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06); border-radius: 4px">
      <template #num="{ row }">
        <span>{{ row.rowOrder }}</span>
      </template>
      <template #orgInfo="{ row }">
        <span @click="clickOnRow(row)">{{ row.mainOrg.owner.name }}</span>
        <span class="orgId">{{ row.mainOrg.id }}</span>
      </template>
      <template #docs="{ row }">
        <div class="block__wrapper" @click="applicantDocsDialogOpen(row)">
          <div class="applicantAdmin__docs"></div>
        </div>
      </template>
      <template #fio="{ row }">
        <div style="display: flex; flex-direction: column; margin-left: 16px">
          <span>{{ row.mainLastName }}</span>
          <span>{{ `${row.mainFirstName} ${row.mainSecondName}` }}</span>
          <div class="educInfo" style="display: flex; flex-direction: row">
            <span>
              {{ `Студент • ${row.mainFo.name} • ${row.mainCourse} курс/год обучения` }}
            </span>
          </div>
        </div>
      </template>
      <template #konvert="{ row }">
        <div v-if="row.konvertConvert" style="display: flex; flex-direction: row">
          <img src="@/assets/icons/konvert.svg" style="margin-right: 10px; align-self: baseline"/>
          <div style="display: flex; flex-direction: column">
            <span>{{ row.konvertConvert }}</span>
            <span class="konvertDate" v-if="row.konvertConvertDate">
              {{ dateFormat(row.konvertConvertDate.slice(0, 10)) }}
            </span>
          </div>
        </div>
      </template>
      <template #stpType="{ row }">
        <div style="display: flex; flex-direction: row; align-items: center">
          <img src="@/assets/icons/cup-grey.svg" style="margin-right: 10px"/>
          <span>{{ row.mainStpType.name }}</span>
        </div>
      </template>
      <template #actions="{ row }">
        <div class="applicantBlocked__wrapper">
          <div
            class="applicant__blocked"
            v-if="orgArray.find((el) => el.id === row.id).blockFlBlock"
            @click="unblockRow(row)"></div>
          <div class="applicant__opened" v-else @click="blockRow(row)"></div>
        </div>
      </template>
    </GridTable>
    <div class="mt-3 d-flex flex-row justify-center align-center">
      <v-autocomplete
        class="pagination-table mr-5"
        v-model="userPages"
        :items="pagesPaginate"
        dense
        filled
        rounded
        height="25"
        style="max-width: 120px; cursor: pointer"
        @change="changePage($event)"></v-autocomplete>
      <v-pagination
        v-model="page"
        :length="pagesVuetify"
        :total-visible="7"
        @input="changePage($event)">
      </v-pagination>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from 'vuex';
import {tablePagination} from '@/2024/components/applicants/admin/journals/tablePagination';
import {GridTable} from '@frontenddevelopers/ined-components/src/lib';
import ApplicantsMainAdminJson from '@/2024/components/applicants/json/ApplicantsMainAdminJson';

export default {
  name: 'AdminLK',
  components: {GridTable},
  mixins: [tablePagination],
  data() {
    return {
      printingOrders: false,
      tableHeaders: [],
      orgArray: [],
      downloadDialog: false,
      applicantDocsDialog: false,
      loading: false,
      character1File: null,
      character2File: null,
      approvalFile: null,
      councilDecisionFile: null,
      academicPerformanceFile: null,
      coverLetterFile: null,
      affiliationMnsFile: null,
      oldFioFile: null,
      search: '',
      searchFio: '',
      docs: {
        row: {konvertConvert: null},
        rowID: null,
        fio: null,
        info: null,
        oldFioRow: false,
        approval: {},
        character1: {},
        character2: {},
        councilDecision: {},
        academicPerformance: {},
        oldFioObject: {},
        coverLetter: {},
        affiliationMns: {},
      },
      loader: {
        button1: false,
        button2: false,
        button3: false,
        button4: false,
        button5: false,
      },
      orders: [
        {
          text: 'Печать приказа по студентам',
          path: 'mns/1',
          loading: false,
        },
      ],
    };
  },

  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getFakeUser: 'getFakeUser',
    }),

    applicantDocsDisabled() {
      if (
        this.docs.row?.blockFlBlock === true &&
        this.docs.row?.adminAgreePatchAdminTbl5Agree === null
      ) {
        return true;
      } else {
        return false;
      }
    },

    character1Computed() {
      if (this.docs.character1 && this.docs.row.konvertConvert) {
        return 'Файл загружен, конверт зарегистрирован';
      } else if (this.docs.character1) {
        return 'Файл загружен';
      } else {
        return 'Файл не загружен';
      }
    },

    character1FileStyleDependsOnKonvert() {
      if (this.applicantDocsDisabled && !this.docs.character1) {
        return false;
      } else {
        return true;
      }
    },

    character2Computed() {
      if (this.docs.character2 && this.docs.row.konvertConvert) {
        return 'Файл загружен, конверт зарегистрирован';
      } else if (this.docs.character2) {
        return 'Файл загружен';
      } else {
        return 'Файл не загружен';
      }
    },

    character2FileStyleDependsOnKonvert() {
      if (this.applicantDocsDisabled && !this.docs.character2) {
        return false;
      } else {
        return true;
      }
    },

    approvalComputed() {
      if (this.docs.approval && this.docs.row.konvertConvert) {
        return 'Файл загружен, конверт зарегистрирован';
      } else if (this.docs.approval) {
        return 'Файл загружен';
      } else {
        return 'Файл не загружен';
      }
    },

    approvalFileStyleDependsOnKonvert() {
      if (this.applicantDocsDisabled && !this.docs.approval) {
        return false;
      } else {
        return true;
      }
    },

    councilDecisionComputed() {
      if (this.docs.councilDecision && this.docs.row.konvertConvert) {
        return 'Файл загружен, конверт зарегистрирован';
      } else if (this.docs.councilDecision) {
        return 'Файл загружен';
      } else {
        return 'Файл не загружен';
      }
    },

    councilDecisionFileStyleDependsOnKonvert() {
      if (this.applicantDocsDisabled && !this.docs.councilDecision) {
        return false;
      } else {
        return true;
      }
    },

    academicPerformanceComputed() {
      if (this.docs.academicPerformance && this.docs.row.konvertConvert) {
        return 'Файл загружен, конверт зарегистрирован';
      } else if (this.docs.academicPerformance) {
        return 'Файл загружен';
      } else {
        return 'Файл не загружен';
      }
    },

    academicPerformanceFileStyleDependsOnKonvert() {
      if (this.applicantDocsDisabled && !this.docs.academicPerformance) {
        return false;
      } else {
        return true;
      }
    },

    coverLetterComputed() {
      if (this.docs.coverLetter && this.docs.row.konvertConvert) {
        return 'Файл загружен, конверт зарегистрирован';
      } else if (this.docs.coverLetter) {
        return 'Файл загружен';
      } else {
        return 'Файл не загружен';
      }
    },

    coverLetterFileStyleDependsOnKonvert() {
      if (this.applicantDocsDisabled && !this.docs.coverLetter) {
        return false;
      } else {
        return true;
      }
    },

    affiliationMnsComputed() {
      if (this.docs.affiliationMns && this.docs.row.konvertConvert) {
        return 'Файл загружен, конверт зарегистрирован';
      } else if (this.docs.affiliationMns) {
        return 'Файл загружен';
      } else {
        return 'Файл не загружен';
      }
    },

    affiliationMnsFileStyleDependsOnKonvert() {
      if (this.applicantDocsDisabled && !this.docs.affiliationMns) {
        return false;
      } else {
        return true;
      }
    },

    oldFioComputed() {
      if (this.docs.oldFioObject && this.docs.row.konvertConvert) {
        return 'Файл загружен, конверт зарегистрирован';
      } else if (this.docs.oldFioObject) {
        return 'Файл загружен';
      } else {
        return 'Файл не загружен';
      }
    },

    oldFioObjectFileStyleDependsOnKonvert() {
      if (this.applicantDocsDisabled && !this.docs.oldFioObject) {
        return false;
      } else {
        return true;
      }
    },

    showingData() {
      // поиск
      return this.orgArray.filter(
        (el) =>
          (!this.searchFio ||
            `${el.mainLastName} ${el.mainFirstName} ${el.mainSecondName}`
              .toLowerCase()
              .search(this.searchFio.toLowerCase()) !== -1) &&
          (!this.search ||
            el.mainOrg.owner.name.toLowerCase().search(this.search.toLowerCase()) !== -1),
      );
    },
  },
  created() {
    this.getAllAdmin();
    this.tableHeaders = ApplicantsMainAdminJson(this);
  },
  methods: {
    ...mapActions('keycloak', {initFakeUser: 'initFakeUser'}),

    async getSelectedOrder(path) {
      // print
      const selectedOrder = this.orders.find((order) => order.path === path);
      selectedOrder.loading = true;
      let uri = `/api/2024/reports/grant_recipients/${path}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      selectedOrder.loading = false;
      this.saveByteArray2(`${selectedOrder.text}.docx`, responseAxios.data, 'docx');
    },

    async getAllAdmin() {
      this.loading = true;
      let uri = `/api/2024/mns/getAll`;
      try {
        const res = await axios.get(uri, {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          this.orgArray = res.data;
          for (let a = 0; a < this.orgArray.length; a++) {
            this.orgArray[a].rowOrder = a + 1;
          }
          this.loading = false;
          console.log('getAllAdmin успех');
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },

    async filePost(fileType, file) {
      const formData = new FormData();
      formData.append('fileType', fileType);
      formData.append('file', file);
      formData.append('rowId', this.docs.rowID);
      if (file.type === "application/pdf") {
        try {
          const res = await axios.post(`/api/2024/mns/upload/${fileType}`, formData, {
            headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
          });
          if (res.data) {
            if (fileType === 'FILE_CHARACTER_1') {
              this.docs.character1 = res.data.character1;
            } else if (fileType === 'FILE_CHARACTER_2') {
              this.docs.character2 = res.data.character2;
            } else if (fileType === 'FILE_SOGL') {
              this.docs.approval = res.data.approval;
            } else if (fileType === 'FILE_SOVET') {
              this.docs.councilDecision = res.data.councilDecision;
            } else if (fileType === 'FILE_COVER_LETTER') {
              this.docs.coverLetter = res.data.coverLetter;
            } else if (fileType === 'FILE_AFFILATIONS') {
              this.docs.affiliationMns = res.data.affiliationMns;
            } else if (fileType === 'FILE_USPEH') {
              this.docs.academicPerformance = res.data.academicPerformance;
            } else if (fileType === 'FILE_OLD_FIO') {
              this.docs.oldFioObject = res.data.oldFio;
            }

            await this.getAllAdmin();
            this.loading = false;
            this.character1File = null;
            this.character2File = null;
            this.approvalFile = null;
            this.councilDecisionFile = null;
            this.academicPerformanceFile = null;
            this.affiliationMnsFile = null;
            this.coverLetterFile = null;
            this.oldFioFile = null;
            console.log('filePost успех');
          }
        } catch (e) {
          this.loading = false;
          console.log(e);
        }
      } else {
        alert(`файл должен соответствовать формату pdf`)
      }
    },

    async getFile(id, name, ext) {
      let uri = `/api/2024/file/scan/download?file=${id}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray(`${name}.${ext}`, responseAxios.data, ext);
    },

    saveByteArray(reportName, byte, ext) {
      let blob = new Blob([byte], {type: `application/${ext}`});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank'; // вариант открытия в новом окне
      // link.download = reportName; // вариант скачивания
      link.click();
    },

    saveByteArray2(reportName, byte, ext) {
      let blob = new Blob([byte], {type: `application/${ext}`});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      // link.target = '_blank'; // вариант открытия в новом окне
      link.download = reportName; // вариант скачивания
      link.click();
      this.loader = {
        button1: false,
        button2: false,
        button3: false,
        button4: false,
        button5: false,
      };
    },

    async deleteFile(ID, fileType) {
      // fileType - костыль на визуальное удаление файлов налету
      this.loading = true;
      let uri = `/api/2024/file/scan/delete?fileId=${ID}`;
      try {
        const res = await axios.delete(uri, {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          if (fileType === 'FILE_CHARACTER_1') {
            this.docs.character1 = null;
          } else if (fileType === 'FILE_CHARACTER_2') {
            this.docs.character2 = null;
          } else if (fileType === 'FILE_SOGL') {
            this.docs.approval = null;
          } else if (fileType === 'FILE_SOVET') {
            this.docs.councilDecision = null;
          } else if (fileType === 'FILE_USPEH') {
            this.docs.academicPerformance = null;
          } else if (fileType === 'FILE_COVER_LETTER') {
            this.docs.coverLetter = null;
          } else if (fileType === 'FILE_AFFILATIONS') {
            this.docs.affiliationMns = null;
          } else if (fileType === 'FILE_OLD_FIO') {
            this.docs.oldFioObject = null;
          }

          await this.getAllAdmin();
          console.log('deleteFile успех');
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },

    dateFormat(arg) {
      let x = arg.replace(/-/g, '.');
      let years = x.slice(0, 4);
      let days = x.slice(-2);
      let months = x.slice(4, -2);
      return `${days}${months}${years}`;
    },

    async blockRow(row) {
      try {
        const res = await axios.put(`/api/2024/mns/block?rowId=${row.id}`, '', {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          this.orgArray.find((el) => el.id === row.id).blockFlBlock = true;
          // this.orgArray[row.rowOrder - 1] = res.data;
          console.log('blockRow успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async unblockRow(row) {
      try {
        const res = await axios.put(`/api/2024/mns/unBlock?rowId=${row.id}`, '', {
          headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
        });
        if (res.status === 200 && res.data) {
          this.orgArray.find((el) => el.id === row.id).blockFlBlock = false;
          // this.orgArray[row.rowOrder - 1] = res.data;
          console.log('unblockRow успех');
        }
      } catch (e) {
        console.log(e);
      }
    },

    async getInventory(ID) {
      let uri = `/api/2024/reports/mns/inventory/${ID}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray(`Report.pdf`, responseAxios.data, 'pdf');
    },

    async getCheckList(ID) {
      // print
      let uri = `/api/2024/reports/mns/check_list/${ID}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray(`Report.pdf`, responseAxios.data, 'pdf');
    },

    async getAppendix1(ID) {
      // print
      let uri = `/api/2024/reports/mns/appendix_1/${ID}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray(`Report.pdf`, responseAxios.data, 'pdf');
    },

    async getAppendix2(ID) {
      // print
      let uri = `/api/2024/reports/mns/appendix_2/${ID}`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray(`Report.pdf`, responseAxios.data, 'pdf');
    },

    async getApplicantDataExcel() {
      // print
      this.loader.button1 = true;
      let uri = `/api/2024/reports/applicant_data/mns`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray2(`Данные Претендентов(МНС).xlsx`, responseAxios.data, 'xlsx');
    },

    async getApplicantSelectionExcel() {
      // print
      this.loader.button2 = true;
      let uri = `/api/2024/reports/applicant_selection/mns`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray2(`Отбор Претендентов(МНС).xlsx`, responseAxios.data, 'xlsx');
    },

    async getSelectedApplicationsSumExcel() {
      // print
      this.loader.button3 = true;
      let uri = `/api/2024/reports/selected_applications/mns`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray2(`Количество поданных заявок(МНС).xlsx`, responseAxios.data, 'xlsx');
    },

    async getSubmittedApplicationsSumExcel() {
      // print
      this.loader.button4 = true;
      let uri = `/api/2024/reports/submitted_applications/mns`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray2(`Количество отобранных заявок(МНС).xlsx`, responseAxios.data, 'xlsx');
    },

    async getOrgDocsExcel() {
      // print
      this.loader.button5 = true;
      let uri = `/api/2024/reports/org_documents/mns`;
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        headers: {Authorization: `Bearer   ${this.getAuthData.token}`},
      });
      this.saveByteArray2(`Уставы, ЕГРЮЛ.xlsx`, responseAxios.data, 'xlsx');
    },

    async applicantDocsDialogOpen(row) {
      this.docs.row = row;

      if (
        row.mainEdit2022OldFirstName ||
        row.mainEdit2022OldLastName ||
        row.mainEdit2022OldSecondName
      ) {
        this.docs.oldFioRow = true;
      } else {
        this.docs.oldFioRow = false;
      }

      this.character1File = null;
      this.character2File = null;
      this.approvalFile = null;
      this.councilDecisionFile = null;
      this.academicPerformanceFile = null;
      this.affiliationMnsFile = null;
      this.coverLetterFile = null;
      this.oldFioFile = null;

      this.docs.rowID = row.id;
      this.docs.approval = row.approval;
      this.docs.character1 = row.character1;
      this.docs.character2 = row.character2;
      this.docs.councilDecision = row.councilDecision;
      this.docs.academicPerformance = row.academicPerformance;
      this.docs.coverLetter = row.coverLetter;
      this.docs.affiliationMns = row.affiliationMns;
      this.docs.oldFioObject = row.oldFio;
      this.docs.fio = `${row.mainLastName} ${row.mainFirstName} ${row.mainSecondName}`;
      this.docs.info = `Студент • ${row.mainFo.name} • ${row.mainCourse} курс/год обучения`;
      this.applicantDocsDialog = true;
      console.log('applicantDocsDialogOpen');
    },

    async clickOnRow(e) {
      await this.initFakeUser({
        id: e.mainOrg.owner.id,
        name: e.mainOrg.owner.name,
        admin: true,
      });
      await this.$router.push('/info');
      await this.$router.push('/mns-2024/applicants');
    },
  },
};
</script>

<style lang="scss"></style>
